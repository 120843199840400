import axios, { AxiosResponse } from 'axios';

export default class ReCaptchaService {
    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId) this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/recaptcha`;
    }

    public async getRecaptchaResponse(token: any): Promise<any> {
        try {
            const res: AxiosResponse<any> = await axios.post(`${this.endpoint}/token`, token);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }
}